<template>
  <div class='reserve-form-wrap'>
    <!-- 공통 전화번호 입력 -->
    <div class="reserve-form-wrap--keypad" v-show="VIEW_MODE == 1">
      <div class="keypad-top">
        <div class="reserve-form-wrap--keypad-in" style="line-height:1.7">
          <div style="width: 100%; text-align: center;">
            <p class="notification-talk">상담 접수 후 알림톡을 꼭 확인해주세요.</p>
          </div>
          <div class="reserve-form-wrap--keypad-top">
            <input type="text" readonly :value="CUST_PHONE">
          </div>
        </div>
        <div class="reserve-form-wrap--keypad-pad">
          <div v-for="(i) in 9">
            <button type="button" @click="fnClickNumber(i)">{{ i }}</button>
          </div>
          <div><button type="button" class="btn-keypad-del" @click="fnDeleteNumber"><span class="hide">del</span></button>
          </div>
          <div><button type="button" @click="fnClickNumber(0)">0</button></div>
          <div><button type="button" class="btn-keypad-in" @click="fnNumberProc"
              style="font-size: 6vw; font-size: 4vh;">입력</button></div>
        </div>
      </div>

      <!-- 대기팀 -->
      <div class="reserve-form-wrap--waiting">
        <div class="reserve-form-wrap--waiting__inner">
          <div class="reserve-form-wrap--waiting-top">
            <span>현재대기</span>
            <div class="reserve-form-wrap--waiting-top--team-box">
              <strong class="team-number">
                <span>{{ WAIT_COUNT }}</span>팀
              </strong>
            </div>
            <p class="reserve-form-wrap--waiting-top__description">상담을 원하시는 고객님께서는<br>
              <em>휴대전화 번호로 상담신청</em>을 해주시기 바랍니다.
            </p>
          </div>

          <div class="terms-wrapper">
            <div class="checkbox">
              <input type="checkbox" id="allCheck" v-model="ALL_AGREE">
              <label for="allCheck">전체 동의하기</label>
            </div>
            <div class="checkbox">
              <input type="checkbox" id="privacyCheckId" v-model="PRSN_AGRE">
              <label for="privacyCheckId">
                <!-- 약관 링크로 -->
                <a id="privacyCheck" @click="termView('prsn')">개인정보 수집 및 이용 동의 <span>(필수)</span></a>
              </label>
            </div>
            <div class="checkbox">
              <input type="checkbox" id="marketingCheck" v-model="MKTG_AGRE">
              <label for="marketingCheck">
                <!-- 약관 링크로 -->
                <a @click="termView('mktg')">마케팅 활용 동의 <span>(선택)</span></a>
              </label>
            </div>
          </div>
        </div>

        <!-- 약관보기 팝업 -->
        <div>
          <v-dialog max-width="650" min-height="950" persistent v-if="CAP0541_POPUP_YN" v-model="CAP0541_POPUP_YN"
            content-class="square-modal min-auto">
            <dialog-CAP0541 headerTitle="약관관리" :param="CAP0541_POPUP_PARAMS" @hide="hideDialog()">
            </dialog-CAP0541>
          </v-dialog>
        </div>

      </div>
    </div>

    <!-- 방문등록 완료폼 -->
    <div class="reserve-form-wrap--complete" v-show="VIEW_MODE == 2">
      <div class="reserve-form-wrap--complete__inner">
        <div class="reserve-form-wrap--complete__text-box">
          <!-- 유저 이름 맨마지막 *표시로 비노출 -->
          <span class="reserve-form-wrap--complete__user"><strong>{{ CUST_NAME }}</strong>님</span>
          <p class="reserve-form-wrap--complete__text">상담예약이 완료되었습니다.</p>
          <span style="font-size:50px;">대기번호 {{ CNSL_WAIT_NUM }} 번<br /><br /></span>
          <p class="reserve-form-wrap--complete__description">
            카카오 알림톡을 통해 상담순서를<br>
            알려드리니 편안한 관람되시기 바랍니다.
          </p>
        </div>

        <div class="reserve-form-wrap--terms-btn">
          <!-- <button type="button" class="btn-keypad-del" @click="fnBackView"><span class="hide">del</span></button> -->
          <button type="button" class="btn-keypad-in" @click="fnNumberEnd">확인</button>
        </div>
      </div>

      <!-- 대기팀 -->
      <div class="reserve-form-wrap--waiting">
        <div class="reserve-form-wrap--waiting__inner">
          <div class="reserve-form-wrap--waiting-top">
            <div class="reserve-form-wrap--waiting-top--team-box">
              <span>현재대기</span>
              <strong class="team-number">
                <span>{{ WAIT_COUNT }}</span>팀
              </strong>
            </div>
            <p class="reserve-form-wrap--waiting-top__description">상담을 원하시는 고객님께서는<br>
              <em>휴대전화 번호로 상담신청</em>을 해주시기 바랍니다.
            </p>
          </div>

          <div class="reserve-form-wrap--waiting-bottom">
            <p class="reserve-form-wrap--waiting-bottom__description">상담석이 지정되면 카카오 알림톡으로 알려드리며,
              현재 대기상황을 확인할 수 있습니다.</p>
            <div class="reserve-form-wrap--waiting-bottom__icon"></div>
          </div>
        </div>

      </div>
    </div>

  </div>
</template>
    
<script>
import DialogCAP0541 from "./CAP0541.vue";

export default {
  name: 'MENU_CAP0560M',
  components: {
    DialogCAP0541
  },
  metaInfo() {
    return {
      title: "상담예약",
    }
  },
  props: {
  },
  data() {
    return {
      VIEW_MODE: 0,             // 화면 상태 {0: 빈화면, 1:입력화면, 2:처리결과화면}
      CAP0541_POPUP_YN: false,  // 약관 팝업
      CAP0541_POPUP_PARAMS: {}, // 약관 팝업 전달 값
      CUST_PHONE: '010-',   // 고객 전화번호
      CUST_NAME: '',        // 고객 이름
      CMP_STDT: '',                                                   // 캠페인 시작일
      CMP_ENDT: '',                                                   // 캠페인 종료일
      BUSS_CODE: this.$route.query.buss_code.replace(/[\"\']/gi, ''), // 비즈니스 코드(url 추출)
      CMP_ID: this.$route.query.cmp_id.replace(/[\"\']/gi, ''),       // 캠페인 ID(url 추출)
      CMP_NAME: this.$route.query.cmp_name.replace(/[\"\']/gi, ''),   // 캠페인 이름(url 추출)
      WAIT_COUNT: 0,                    // 대기 인원수
      WAIT_COUNT_INTERVAL:'',           // 대기 인원 체크(3초)
      CNSL_WAIT_NUM: 0,                 // 고객 대기번호
      ALERT_MSG: {                      // 알림 메시지
        ERROR: '관리자에게 문의해주세요.',
        RESERVATION_DONE: '이미 상담예약 신청을 하셨습니다.',
        COUNSELING_CUSTOMER: '상담중인 고객입니다.',
        COUNSELING_DONE: '상담완료 고객입니다.',
        CAMPAIGN_ERROR: '해당 캠페인은 존재하지 않습니다.\n다른 캠페인을 선택해주세요.',
        PRSN_AGREE: '개인정보 수집 및 이용 동의를 체크해주세요.',
        PHONE_ERROR: '휴대전화번호를 정확히 입력해 주세요.',
        ALARAM_TALK_ERROR: '알림톡 오류\n관리자에게 문의해주세요.',
      },
      PRSN_AGRE: false, // 개인정보 동의(필수)
      MKTG_AGRE: false, // 마케팅 동의(선택)
    }
  },
  created() {
    this.init();
  },
  mounted() {
    // 대기인원 조회
    this.WAIT_COUNT_INTERVAL = setInterval(this.waitingCount, 3000);
  },
  computed: {
    // 전체동의
    ALL_AGREE: {
      get: function() {
        return this.PRSN_AGRE && this.MKTG_AGRE ? true : false;
      },
      set: function(e) {
        this.PRSN_AGRE = e ? true : false;
        this.MKTG_AGRE = e ? true : false;
      }
    }
  },
  methods: {
    init() {
      this.checkCampaign();
    },

    // 현재 캠페인이 사용가능한지 체크
    async checkCampaign() {
      let requestData = {
        headers: {},
        sendData: {},
      };
      requestData.headers["URL"] = "/api/hlw/campaign/work/validation/check";
      requestData.sendData["BUSS_CODE"] = this.BUSS_CODE;
      requestData.sendData["CMP_ID"] = this.CMP_ID;

      let response = await this.common_postCall(requestData);

      if (response.HEADER.ERROR_FLAG) {
        this.common_alert(this.ALERT_MSG.CAMPAIGN_ERROR, "error");
        return;
      }
      this.VIEW_MODE = 1;
      this.CMP_STDT = response.DATA[0].CMP_STDT;
      this.CMP_ENDT = response.DATA[0].CMP_ENDT;

      this.waitingCount()
    },

    // 현재 대기중인 인원수 가져오기
    async waitingCount() {
      // 캠페인이 존재하지 않는 경우 종료
      if(this.VIEW_MODE == 0) {
        clearInterval(this.WAIT_COUNT_INTERVAL);
        return;
      }

      let requestData = {
        headers: {},
        sendData: {},
      };
      requestData.headers["URL"] = "/api/hlw/campaign/work/waiting/select";
      requestData.sendData["BUSS_CODE"] = this.BUSS_CODE;
      requestData.sendData["CMP_ID"] = this.CMP_ID;

      let response = await this.common_postCall(requestData);
      
      if (response.HEADER.ERROR_FLAG) {
        this.common_alert(this.ALERT_MSG.CAMPAIGN_ERROR, "error");
        this.VIEW_MODE = 0;
        return;
      } 
      this.WAIT_COUNT = response.DATA[0].WAITING_COUNT;
    },

    // 약관보기
    termView(term) {
      this.CAP0541_POPUP_PARAMS = {
        bussCode: this.BUSS_CODE,
        termType: term,
      };
      this.showDialog();
    },

    // 모달 팝업 열기
    showDialog() {
      this.CAP0541_POPUP_YN = true;
    },

    // 모달 팝업 닫기
    hideDialog() {
      this.CAP0541_POPUP_YN = false;
    },

    fnClickNumber(clickNumber) {
      if (this.CUST_PHONE.length < 13) {
        this.CUST_PHONE = this.fnNumberFomat(this.CUST_PHONE + clickNumber);
      }
    },

    fnDeleteNumber() {
      if (this.CUST_PHONE.length > 4) {
        this.CUST_PHONE = this.fnNumberFomat(this.CUST_PHONE.replaceAll('-', '').slice(0, -1));
      }
    },

    async fnNumberProc() {
      // 개인정보 체크 확인
      if (!this.PRSN_AGRE) {
        this.common_alert(this.ALERT_MSG.PRSN_AGREE, 'chk');
        return;
      }

      // 전화번호 확인
      if (this.CUST_PHONE.length < 13) {
        this.common_alert(this.ALERT_MSG.PHONE_ERROR, 'chk');
        return;
      } 

      let requestData = {
          headers: {},
          sendData: {},
        };
      requestData.headers["URL"] = "/api/hlw/campaign/work/counseling/status";
      requestData.sendData["CMP_ID"] = this.CMP_ID;
      requestData.sendData["BUSS_CODE"] = this.BUSS_CODE;
      requestData.sendData["CUST_PHONE"] = this.CUST_PHONE.replaceAll("-", "");
      requestData.sendData["CMP_STDT"] = this.CMP_STDT;
      requestData.sendData["CMP_ENDT"] = this.CMP_ENDT;

      let response = await this.common_postCall(requestData);

      if (response.HEADER.ERROR_FLAG) {
        this.fnNumberEnd();
        this.common_alert(this.ALERT_MSG.ERROR, "error");
        return;
      } 

      let resData = response.DATA;

      // 현장 방문 고객 등록(사전 예약X)
      if(resData.length == 0) {
        this.visitorRegist();
        return;
      }

      //방문 구분 코드(CNSL_VISIT_CD_01: 미방문, CNSL_VISIT_CD_02: 방문완료)
      if(resData[0].VIST_GUB_CD == 'CNSL_VISIT_CD_02') {
        this.fnNumberEnd();
        this.common_alert(this.ALERT_MSG.RESERVATION_DONE, "chk");
      } else {
        this.visitorRegist(resData[0]);
      }
    },

    // 방문 고객 등록
    async visitorRegist(data) {
      let requestData = {
        headers: {},
        sendData: {}
      };
      requestData.headers["URL"] = "/api/hlw/campaign/work/visitor/regist";
      requestData.sendData["CMP_ID"] = this.CMP_ID; 
      requestData.sendData["BUSS_CODE"] = this.BUSS_CODE; 
      requestData.sendData["CUST_PHONE"] = this.CUST_PHONE.replaceAll("-", ""); 
      requestData.sendData["CNSL_STAT_CD"] = 'CNSL_STAT_CD_01';
      requestData.sendData["TRGT_GUB_CD"] = 'CUST_TRGT_CD_02';  //현장등록( 01: 사전예약, 02 : 현장등록)
      requestData.sendData["VIST_GUB_CD"] = 'CNSL_VISIT_CD_02';
      requestData.sendData["PRSN_AGRE_YN"] = this.PRSN_AGRE ? 'Y' : 'N';
      requestData.sendData["MKTG_AGRE_YN"] = this.MKTG_AGRE ? 'Y' : 'N';

      // 사전예약 고객 데이터
      if(data != null) {
        requestData.sendData["CMP_CNSL_ID"] = data.CMP_CNSL_ID;
        requestData.sendData["CNSL_RSVT_DT"] = data.CNSL_RSVT_DT;
        requestData.sendData["QUTN_ID"] = data.QUTN_ID;
        requestData.sendData["QUTN_ITEM_LIST"] = data.QUTN_ITEM_LIST;
        requestData.sendData["CHK_QUTN_RPLY"] = data.CHK_QUTN_RPLY;
        requestData.sendData["SEL_QUTN_RPLY"] = data.SEL_QUTN_RPLY;
        requestData.sendData["TXT_QUTN_RPLY"] = data.TXT_QUTN_RPLY;
        requestData.sendData["ETC_QUTN_RPLY"] = data.ETC_QUTN_RPLY;
        requestData.sendData["IMG_QUTN_RPLY"] = data.IMG_QUTN_RPLY;
        requestData.sendData["DATE_QUTN_RPLY"] = data.DATE_QUTN_RPLY;
        requestData.sendData["UPLD_GROUP_ID"] = data.UPLD_GROUP_ID;
      }

      let response = await this.common_postCall(requestData);

      if (response.HEADER.ERROR_FLAG) {
        this.fnNumberEnd();
        this.common_alert(this.ALERT_MSG.ERROR, "error");
        return;
      } 
      
      let resData = response.DATA[0];

      //고객 상담현황 코드( 01: 상담접수, 02: 상담중, 03: 미처리, 04: 상담완료)
      switch (resData.CNSL_STAT_CD) {
        case "CNSL_STAT_CD_01":
          this.fnNumberEnd();
          this.common_alert(this.ALERT_MSG.RESERVATION_DONE, "chk");
          return;
        case "CNSL_STAT_CD_02":
          this.fnNumberEnd();
          this.common_alert(this.ALERT_MSG.COUNSELING_CUSTOMER, "chk");
          return;
        case "CNSL_STAT_CD_04":
          this.fnNumberEnd();
          this.common_alert(this.ALERT_MSG.COUNSELING_DONE, "chk");
          return;
      }

      this.CUST_NAME = this.CUST_PHONE.substring(0, this.CUST_PHONE.length - 4) + '****';
      if (resData.CUST_NAME != null && resData.CUST_NAME != "") {
        this.CUST_NAME = resData.CUST_NAME.substring(0, resData.CUST_NAME.length - 1) + '*';
      }
      this.CNSL_WAIT_NUM = resData.CNSL_WAIT_NUM;

      this.sendMessage();
    },
    
    //알림톡 발송
    async sendMessage() {
      let requestData = {
        headers: {},
        sendData: {},
      };
      requestData.headers["URL"] = "/api/hlw/campaign/alarmTalk-msgformat/manage/list";
      requestData.sendData["BUSS_CODE"] = this.BUSS_CODE;              //사업지 아이디
      requestData.sendData["CMP_ID"] = this.CMP_ID;                   //캠페인 아이디
      requestData.sendData["MSG_TMPL_TYPE"] = "APP_CONSULT";          //메세지 속성 코드(신청완료)    

      let response = await this.common_postCall(requestData);
      let data = response.DATA;
      let data_btn = response.DATA_BTN;

      if (response.HEADER.ERROR_FLAG || data.length == 0) {
        this.fnNumberEnd();
        this.common_alert(this.ALERT_MSG.ALARAM_TALK_ERROR, "error");
        return;
      }

      let msgFormat = data[0].MSG_CONT;  //메세지내용
      msgFormat = msgFormat.replace(/#{사업지명}/g, this.CMP_NAME)
                           .replace(/#{대기번호1}/g, this.CNSL_WAIT_NUM)
                           .replace(/#{대기자수}/g, this.WAIT_COUNT)

      let setButtons = [];
      if (data_btn.length > 0) {

        for (let i = 0; i < data_btn.length; i++) {
          setButtons.push({
            name: data_btn[i].BTN_NAME,
            type: data_btn[i].BTN_LINK_TYPE,
            url_mobile: data_btn[i].BTN_LINK_URL
          });
        }
      }

      const sendButton = { "button": setButtons };

      let requestAlarmTalkData = {
            headers: {},
            sendData: {},
      };

      let buttonToString = `'${JSON.stringify(sendButton)}'`;

      requestAlarmTalkData.headers["URL"] = "/api/hlw/campaign/alarmTalk-postmsg/manage/post";

      requestAlarmTalkData.sendData["CMP_ID"] = this.CMP_ID;
      requestAlarmTalkData.sendData["TRAN_SENDER_KEY"] = data[0].SNDR_KEY;
      requestAlarmTalkData.sendData["TRAN_TMPL_CD"] = data[0].MSG_TMPL_CODE;
      requestAlarmTalkData.sendData["TRAN_PHONE"] = this.CUST_PHONE.replaceAll("-", "");
      requestAlarmTalkData.sendData["TRAN_CALLBACK"] = data[0].SNDR_PHONE.replaceAll("-", "");
      requestAlarmTalkData.sendData["TRAN_MSG"] = msgFormat;
      requestAlarmTalkData.sendData["TRAN_REPLACE_MSG"] = msgFormat;
      if(sendButton["button"].length != 0) requestAlarmTalkData.sendData["TRAN_BUTTON"] = this.encode(buttonToString);

      let alarmTalkResponse = await this.common_postCall(requestAlarmTalkData);
      if (alarmTalkResponse.HEADER.ERROR_FLAG) {
        this.fnNumberEnd();
        this.common_alert(this.ALERT_MSG.ALARAM_TALK_ERROR, "error");
        return;
      }
      this.WAIT_COUNT++;
      this.VIEW_MODE = 2;
    },

    fnNumberFomat(phoneNumber) {
      var makePhoneNumber = "";
      if (phoneNumber != undefined && phoneNumber != '') {
        makePhoneNumber = phoneNumber.replaceAll('-', '');
        if (makePhoneNumber.length > 9) {
          makePhoneNumber = this.mixin_setPhoneNo(makePhoneNumber);
        } else {
          if (makePhoneNumber.length === 3) {
            makePhoneNumber = makePhoneNumber + '-';
          } else if (makePhoneNumber.length === 7) {
            makePhoneNumber = makePhoneNumber.substring(0, 3) + '-' + makePhoneNumber.substring(3) + '-';
          } else if (makePhoneNumber.length > 7) {
            makePhoneNumber = makePhoneNumber.substring(0, 3) + '-' + makePhoneNumber.substring(3, 7) + '-' + makePhoneNumber.substring(7);
          } else if (makePhoneNumber.length > 3) {
            makePhoneNumber = makePhoneNumber.substring(0, 3) + '-' + makePhoneNumber.substring(3);
          }
        }
      }
      return makePhoneNumber;
    },

    fnNumberEnd() {
      this.VIEW_MODE = 1;
      this.ALL_AGREE = false;
      this.CUST_PHONE = '010-';
    },

    decode(strText) {

      //값존재유무 체크
      if (strText === "" || strText == null) {
        return strText;
      }
      strText = strText.toString();

      //문자열 길이가 4이상일 경우만 처리
      if (strText.length <= 3) {
        return strText;
      }
      //순서바뀌면 안나옴
      strText = strText.replaceAll("&lt;", "<");
      strText = strText.replaceAll("&gt;", ">");
      strText = strText.replaceAll("&amp;", "&");
      strText = strText.replaceAll("&quot;", '"');
      strText = strText.replaceAll("&apos;", "'");
      strText = strText.replaceAll("<br>", "\r");
      strText = strText.replaceAll("<p>", "\n");
      strText = strText.replaceAll("&#91;", "[");
      strText = strText.replaceAll("&#93;", "]");
      strText = strText.replaceAll("&#40;", "(");
      strText = strText.replaceAll("&#41;", ")");

      return strText;
    },
    encode(strText) {
      // 값 존재 유무 체크
      if (strText === "" || strText == null) {
        return strText;
      }

      strText = strText.toString();

      // 문자열 길이가 4 이상일 경우만 처리
      if (strText.length <= 3) {
        return strText;
      }

      // 문자열을 HTML 엔티티로 인코딩
      strText = strText.replaceAll("&", "&amp;");
      strText = strText.replaceAll("<", "&lt;");
      strText = strText.replaceAll(">", "&gt;");
      strText = strText.replaceAll('"', "&quot;");
      strText = strText.replaceAll("'", "&apos;");
      strText = strText.replaceAll("\r", "<br>");
      strText = strText.replaceAll("\n", "<p>");
      strText = strText.replaceAll("\[", "&#91;");
      strText = strText.replaceAll("\]", "&#93;");
      strText = strText.replaceAll("\(", "&#40;");
      strText = strText.replaceAll("\)", "&#41;");

      return strText;
    }
  },
}
</script>
  
<style scoped>
.reserve-form-wrap {
  font-family: 'Spoqa Han Sans Neo', 'sans-serif' !important;
}

.reserve-form-wrap--complete,
.reserve-form-wrap--keypad {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: #EDF3F3;
}

.reserve-form-wrap--complete {
  padding-top: 0;
}

.reserve-form-wrap--complete__inner,
.keypad-top {
  background: #fff;
}

.reserve-form-wrap--complete__inner {
  padding-top: 150px;
}

.notification-talk {
  display: inline-block;
  padding: 20px 50px 0 0;
  line-height: 39px;
  font-size: 20px;
  font-weight: 300;
  color: #727171;
  background: url("../../../assets/icon_kakao.svg") right center / 40px no-repeat;
}

.reserve-form-wrap--terms-btn {
  margin-top: 150px;
}

.reserve-form-wrap--terms-btn .btn-keypad-del,
.reserve-form-wrap--terms-btn .btn-keypad-in {
  height: 120px;
  font-size: 40px;
}

.reserve-form-wrap .reserve-form-wrap--waiting .reserve-form-wrap--waiting-bottom .reserve-form-wrap--waiting-bottom__icon {
  margin-top: 10px;
}

.terms-wrapper {
  margin-top: 30px;
}

.checkbox:not(:last-of-type) {
  margin-bottom: 15px;
}

.checkbox input[type="checkbox"] {
  display: none;
}

.checkbox input[type="checkbox"]+label {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 20px;
  color: #222;
  cursor: pointer;
}

.checkbox input[type="checkbox"]+label::before {
  content: "";
  width: 30px;
  height: 30px;
  background: url("../../../assets/icon_check.svg") left center / 30px no-repeat;
  filter: brightness(.4);
  opacity: 0.3;
  transition: 0.2s all;
}

.checkbox input[type="checkbox"]:checked+label::before {
  background: url("../../../assets/icon_check.svg") left center / 30px no-repeat;
  filter: none;
  opacity: 1;
}

.checkbox input[type="checkbox"]+label a {
  text-decoration: none;
  color: #222;
}

.checkbox input[type="checkbox"]+label span {
  color: #858585;
}
</style>
  